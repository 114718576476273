<template>
  <v-dialog v-model="dialog" persistent max-width="750">
    <!-- <pre>{{ stages }}</pre> -->
    <v-card class="text-center">
      <v-card-title class="text-center">
        {{ $t("Insert_stages_position")
        }}<v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">
              mdi-information-variant-circle-outline
            </v-icon>
          </template>
          <div
            style="width: 360px;"
            v-html="$t('the_stages_recruitment_process')"
          ></div>
        </v-tooltip>
      </v-card-title>
      <v-container class="fill-heigt pt-0" fluid>
        <v-row class="justify-center">
          <v-col cols="12" md="12">
            <v-select
              v-model="vacancyID"
              :items="vacancies"
              item-text="title"
              item-value="id"
              :label="$t('Select_the_job_from_which_stages')"
              dense
              outlined
              hide-details
              class="mx-3"
              @change="copyStages()"
            ></v-select>
          </v-col>
          <v-col cols="12" md="12" style="margin-top: -1.5%;">
            <v-divider class="mx-3"></v-divider>
          </v-col>
          <v-col cols="12" md="12">
            <template v-for="(stage, index) in stages">
              <v-col cols="12" md="12" :key="`${index}stage`">
                <v-row>
                  <v-col cols="12" md="11">
                    <v-text-field
                      v-model="stage.name"
                      :label="$t('add_name_stage')"
                      outlined
                      dense
                      maxlength="61"
                      :rules="[
                        () => !!stage.name || $t('required_field'),
                        () =>
                          (!!stage.name && stage.name.length <= 60) ||
                          $t('maximum_60_characters_long'),
                      ]"
                    ></v-text-field>
                    <v-textarea
                      v-model="stage.analyze"
                      :label="$t('what_aspects_intend_analyze')"
                      auto-grow
                      type="text"
                      dense
                      outlined
                      rows="1"
                      :rules="requiredRules"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="1" class="text-center mt-1">
                    <v-btn
                      x-small
                      outlined
                      fab
                      color="secondary"
                      @click="index === 0 ? addStage() : removeStage(index)"
                    >
                      <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                      <v-icon v-else small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row style="margin-top: -3%;">
                  <v-col cols="12" md="5">
                    <v-menu
                      ref="menu"
                      v-model="stage.menu"
                      :close-on-content-click="false"
                      :close-on-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="stage.startDate"
                          :label="$t('start_date_time')"
                          persistent-hint
                          prepend-inner-icon="event"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            stage.menu = true;
                            stage.menu1 = false;
                            stageIndex = index;
                          "
                        ></v-text-field>
                      </template>
                      <v-card max-width="290" min-height="400">
                        <v-row class="mt-1">
                          <v-col>
                            <v-date-picker
                              v-model="stage.startDate"
                              no-title
                              :min="today"
                              :events="functionEvents"
                            ></v-date-picker>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row style="margin-top: -4%; margin-bottom: 0%">
                          <v-col>
                            <v-alert
                              color="#fdf0d7"
                              dense
                              type="info"
                              class="text-left black--text caption mb-0"
                              ><span style="font-size: 8pt">{{
                                $t("Select_the_date")
                              }}</span></v-alert
                            ></v-col
                          >
                        </v-row>
                        <v-row dense>
                          <v-col md="6">
                            <v-text-field
                              :value="stage.startDate.substring(0, 10)"
                              readonly
                              dense
                              :placeholder="$t('date')"
                              solo
                              class="black--text caption"
                            ></v-text-field>
                          </v-col>
                          <v-col md="6">
                            <v-text-field
                              class="black--text caption"
                              solo
                              dense
                              :placeholder="$t('enter_the_time')"
                              v-mask="'##:##'"
                              v-model="stage.startTime"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row dense class="mx-1 mb-3">
                          <v-spacer></v-spacer>
                          <v-btn
                            small
                            tile
                            depressed
                            outlined
                            color="#fdf0d7"
                            class="text-body-2 mr-2 mb-2"
                            @click="stage.menu = false"
                          >
                            <span class="black--text text-capitalize">
                              {{ $t("cancel") }}</span
                            >
                          </v-btn>
                          <v-btn
                            small
                            tile
                            depressed
                            color="#fdf0d7"
                            class="text-body-2 mb-2"
                            @click="
                              saveStarDate(
                                stage.startDate,
                                stage.startTime,
                                index
                              )
                            "
                          >
                            <span class="black--text text-capitalize">
                              {{ $t("save") }}</span
                            >
                          </v-btn>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="1"></v-col>
                  <v-col cols="12" md="5">
                    <v-menu
                      ref="menu1"
                      v-model="stage.menu1"
                      :close-on-content-click="false"
                      :close-on-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="stage.endDate"
                          :label="$t('end_date_time')"
                          persistent-hint
                          prepend-inner-icon="event"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            stage.menu = false;
                            stage.menu1 = true;
                            stageIndex = index;
                          "
                        ></v-text-field>
                      </template>
                      <v-card max-width="290" min-height="400">
                        <v-row class="mt-1">
                          <v-col>
                            <v-date-picker
                              v-model="stage.endDate"
                              no-title
                              :min="stage.startDate"
                              :events="functionEvents"
                            ></v-date-picker>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row style="margin-top: -4%; margin-bottom: 0%">
                          <v-col>
                            <v-alert
                              color="#fdf0d7"
                              dense
                              type="info"
                              class="text-left black--text caption mb-0"
                              ><span style="font-size: 8pt">{{
                                $t("Select_the_date")
                              }}</span></v-alert
                            ></v-col
                          >
                        </v-row>
                        <v-row dense>
                          <v-col md="6">
                            <v-text-field
                              :value="stage.endDate.substring(0, 10)"
                              readonly
                              dense
                              solo
                              :placeholder="$t('date')"
                              class="black--text caption"
                            ></v-text-field>
                          </v-col>
                          <v-col md="6">
                            <v-text-field
                              class="black--text caption"
                              solo
                              dense
                              :placeholder="$t('enter_the_time')"
                              v-mask="'##:##'"
                              v-model="stage.endTime"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row dense class="mx-1 mb-3">
                          <v-spacer></v-spacer>
                          <v-btn
                            small
                            tile
                            depressed
                            outlined
                            color="#fdf0d7"
                            class="text-body-2 mr-2 mb-2"
                            @click="stage.menu1 = false"
                          >
                            <span class="black--text text-capitalize">
                              {{ $t("cancel") }}</span
                            >
                          </v-btn>
                          <v-btn
                            small
                            tile
                            depressed
                            color="#fdf0d7"
                            class="text-body-2 mb-2"
                            @click="
                              saveEndDate(stage.endDate, stage.endTime, index)
                            "
                          >
                            <span class="black--text text-capitalize">
                              {{ $t("save") }}</span
                            >
                          </v-btn>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="11" style="margin-top: -3%;">
                    <v-combobox
                      v-model="stage.participants"
                      :items="users"
                      :label="$t('participants')"
                      multiple
                      item-text="name"
                      item-value="id"
                      chips
                      outlined
                      dense
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :key="JSON.stringify(data.item.id)"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          @click:close="data.parent.selectItem(data.item.id)"
                        >
                          <v-avatar
                            class="accent white--text"
                            left
                            v-if="data.item.photo"
                          >
                            <v-img
                              :src="
                                data.item.photo
                                  ? `${apiUrl}/images/user/${data.item.photo}`
                                  : `/avatar.png`
                              "
                          /></v-avatar>

                          <v-avatar
                            v-else
                            class="accent white--text"
                            left
                            v-text="data.item.name.slice(0, 1).toUpperCase()"
                          ></v-avatar>

                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row
                  style="margin-top: -2%;"
                  class="mb-1"
                  v-show="stages.length - 1 != index"
                >
                  <v-divider></v-divider>
                </v-row>
              </v-col>
            </template>
          </v-col>
          <v-col cols="12" md="12" class="text-center mb-10">
            <v-btn text small color="primary" @click="close()">
              {{ $t("close") }}
            </v-btn>
            <v-btn small color="primary" @click="save()">
              {{ $t("save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  UPDATE_STAGES_MUTATION,
  DELETE_STAGE_MUTATION,
} from "../graphql/Mutation.gql";
import { GET_USERS_ENTITY_QUERY } from "../../vacancy/graphql/Query.resolver";
import { GET_VACANCIES_ENTITY_QUERY } from "../../vacancy/graphql/Query.resolver";
import { API_URL } from "@/api";
import moment from "moment";

export default {
  name: "StageEditDialog",
  props: {
    dialog: Boolean,
    vacancyStages: Array,
    vacancyId: String,
  },
  apollo: {
    users: {
      query: GET_USERS_ENTITY_QUERY,
      fetchPolicy: "no-cache",
    },
    vacancies: {
      query: GET_VACANCIES_ENTITY_QUERY,
      variables() {
        return { private: this.available };
      },

      fetchPolicy: "no-cache",
    },
  },
  data: () => ({
    stageIndex: 0,
    apiUrl: API_URL,
    vacancies: [],
    available: false,
    vacancyID: "",
    stages: [
      {
        menu: false,
        menu1: false,
        name: "",
        analyze: "",
        startDate: "",
        startTime: "10:00",
        endDate: "",
        endTime: "10:00",
        participants: [],
      },
    ],
    indexsToRemove: [],
  }),
  computed: {
    requiredRules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
    today() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    calendarDates() {
      let calendar = [];
      if (this.vacancies) {
        this.vacancies.map((v) => {
          v.stages.map((s) => {
            if (s.startDate && s.endDate) {
              calendar.push({
                start: s.startDate.substring(0, 10),
                end: s.endDate.substring(0, 10),
              });
            }
          });
        });
      }
      return calendar;
    },
  },
  watch: {
    dialog() {
      this.stages = this.vacancyStages;
      this.indexsToRemove = [];
      if (this.stages.length === 0) {
        this.addStage();
      }
      if (this.stages.length) {
        this.stages = this.stages.map((stage) => {
          if (stage.startDate === null) {
            return {
              menu: false,
              menu1: false,
              name: stage.name,
              analyze: stage.analyze,
              startDate: "",
              startTime: "",
              endDate: "",
              endTime: "",
              participants: [],
            };
          } else {
            return {
              ...stage,
              menu: false,
              menu1: false,
            };
          }
        });
      }
    },
  },
  mounted() {
    if (this.stages.length === 0) {
      this.addStage();
    }
  },
  methods: {
    copyStages() {
      let vacancy = this.vacancies.find((v) => v.id === this.vacancyID);
      this.stages = vacancy.stages;
      if (this.stages.length) {
        this.stages = this.stages.map((stage) => {
          if (stage.title !== null || stage.title !== "") {
            return {
              menu: false,
              menu1: false,
              name: stage.name,
              analyze: stage.analyze,
              participants: stage.participants,
              startDate: "",
              startTime: "",
              endDate: "",
              endTime: "",
            };
          } else {
            return {
              name: stage.name,
              analyze: stage.analyze,
              startDate: stage.startDate,
              startTime: stage.startTime,
              endDate: stage.endDate,
              endTime: stage.endTime,
              participants: stage.participants,
              menu: false,
              menu1: false,
            };
          }
        });
      } else {
        this.addStage();
      }
    },
    functionEvents(date) {
      var arrayColor = [];
      this.calendarDates.forEach((element) => {
        if (element.start) {
          if (date === element.start) {
            // if (arrayColor.length <= 2) {
            arrayColor.push("secondary");
            // }
          }
        }
      });
      return arrayColor;
    },
    close() {
      if (this.stages.length === 1) {
        if (this.stages[0].name === "") {
          setTimeout(() => {
            this.removeStage(this.stages.length - 1);
          }, 100);
        }
      }
      this.$emit("close");
    },
    async save() {
      try {
        try {
          this.stages = this.stages.map((stage) => {
            delete stage.menu;
            delete stage.menu1;
            delete stage.id;
            return {
              ...stage,
              participants: stage.participants.map((p) => {
                return p.id;
              }),
            };
          });
          const { data } = await this.$apollo.mutate({
            mutation: UPDATE_STAGES_MUTATION,
            variables: {
              vacancyId: this.vacancyId,
              stages: this.stages,
            },
          });

          this.$emit("done", data.updateStages.stages);
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
    addStage() {
      this.stages.push({
        menu: false,
        menu1: false,
        name: "",
        analyze: "",
        startDate: "",
        startTime: "10:00",
        endDate: "",
        endTime: "10:00",
        participants: [],
      });
    },
    saveStarDate(date, time, index) {
      if (time) {
        this.stages[index].startDate =
          date.substring(0, 10) + ` ${this.$t("at_")} ` + time;
        this.stages[index].menu = false;
        this.stages[index].menu1 = false;
      }
    },
    saveEndDate(date, time, index) {
      if (time) {
        this.stages[index].endDate =
          date.substring(0, 10) + ` ${this.$t("at_")} ` + time;
        this.stages[index].menu = false;
        this.stages[index].menu1 = false;
      }
    },
    async removeStage(index) {
      this.stages.splice(index, 1);
      try {
        try {
          this.$apollo.mutate({
            mutation: DELETE_STAGE_MUTATION,
            variables: {
              id: this.vacancyId,
              stageIndex: index,
            },
          });
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
